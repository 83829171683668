/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UpdateBatch, ViewallBatch, ViewByidBatch } from "../../Api/batch";
import { viewByidStudents } from "../../Api/student";
import {
  addAttendance,
  AttendancebyStudent,
  TodayAttancedata,
} from "../../Api/attendance";
import moment from "moment";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WestIcon from "@mui/icons-material/West";
import Modal from "react-modal";
import { IoMdCloseCircle } from "react-icons/io";
import Module from "./Module";

const BatchInfo = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [module, setmodule] = useState("Modules");
  const [batchdata, setbatchdata] = useState([]);
  const [userlistData, setuserlistData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [todayattenacedata, settodayattenacedata] = useState([]);
  const [newmodel, setnewmodel] = useState(false);
  const [allbatchdata, setallbatchdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectall, setselectall] = useState(false);

  useEffect(() => {
    GetBatchInfo();
  }, [id]);

  const GetBatchInfo = async () => {
    var batchdata = await ViewByidBatch({ id: id });
    if (batchdata.length !== 0) {
      setbatchdata(batchdata);
      if (batchdata[0].users.length !== 0) {
        var userlist = batchdata[0].users.split(",");
        var user_list = [];
        for (var i = 0; i < userlist.length; i++) {
          var userdata = await viewByidStudents({ id: userlist[i] });
          if (userdata.length !== 0) {
            user_list.push(userdata[0]);
          }
        }
        setuserlistData(user_list);
      } else {
        setuserlistData([]);
      }
    }
    var todaydata = await TodayAttancedata();
    settodayattenacedata(todaydata);
    var allbatchdata = await ViewallBatch();
    if (allbatchdata.length !== 0) {
      var otherbatch = await allbatchdata.filter((data) => {
        return data.id !== id;
      });
      setallbatchdata(otherbatch);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };
  const addBulkAtt = async () => {
    if (selectedItems.length !== 0) {
      setloading(true);
      for (var i = 0; i < selectedItems.length; i++) {
        var adminid = selectedItems[i];
        if (adminid !== null) {
          var today = moment().format("YYYY-MM-DD");
          var myattendance = await AttendancebyStudent({ id: adminid });
          if (myattendance.length !== 0) {
            var checkattendance = await myattendance.filter((data) => {
              return data.date == today;
            });
            if (checkattendance.length == 0) {
              var craetedata = await addAttendance({
                id: adminid,
                status: true,
              });
              if (craetedata.message === "SUCCESS") {
                toast.success("Attendance added...", {
                  autoClose: 2000,
                  transition: Slide,
                });
                GetBatchInfo();
              } else {
                toast.error(craetedata.message, {
                  autoClose: 2000,
                  transition: Slide,
                });
              }
            } else {
              toast.error("Already Attendance added today", {
                autoClose: 2000,
                transition: Slide,
              });
            }
          } else {
            var craetedata1 = await addAttendance({
              id: adminid,
              status: true,
            });
            if (craetedata1.message === "SUCCESS") {
              toast.success("Attendance added...", {
                autoClose: 2000,
                transition: Slide,
              });
              GetBatchInfo();
            } else {
              toast.error(craetedata1.message, {
                autoClose: 2000,
                transition: Slide,
              });
            }
          }
        }
      }
      setTimeout(() => {
        setloading(false);
      }, 2000);
    }
  };

  const Presentbtn = async (data) => {
    var adminid = data.data;
    if (adminid !== null) {
      var today = moment().format("YYYY-MM-DD");
      var myattendance = await AttendancebyStudent({ id: adminid });
      if (myattendance.length !== 0) {
        var checkattendance = await myattendance.filter((data) => {
          return data.date == today;
        });
        if (checkattendance.length == 0) {
          var craetedata = await addAttendance({ id: adminid, status: true });
          if (craetedata.message === "SUCCESS") {
            toast.success("Attendance added...", {
              autoClose: 2000,
              transition: Slide,
            });
            GetBatchInfo();
          } else {
            toast.error(craetedata.message, {
              autoClose: 2000,
              transition: Slide,
            });
          }
        } else {
          toast.error("Already Attendance added today", {
            autoClose: 2000,
            transition: Slide,
          });
        }
      } else {
        var craetedata1 = await addAttendance({ id: adminid, status: true });
        if (craetedata1.message === "SUCCESS") {
          toast.success("Attendance added...", {
            autoClose: 2000,
            transition: Slide,
          });
          GetBatchInfo();
        } else {
          toast.error(craetedata1.message, {
            autoClose: 2000,
            transition: Slide,
          });
        }
      }
    }
  };

  const Absentbtn = async (data) => {
    var adminid = data.data;
    if (adminid !== null) {
      var today = moment().format("YYYY-MM-DD");
      var myattendance = await AttendancebyStudent({ id: adminid });
      if (myattendance.length !== 0) {
        var checkattendance = await myattendance.filter((data) => {
          return data.date == today;
        });
        if (checkattendance.length == 0) {
          var craetedata = await addAttendance({ id: adminid, status: false });
          if (craetedata.message === "SUCCESS") {
            toast.success("Attendance added...", {
              autoClose: 2000,
              transition: Slide,
            });
            GetBatchInfo();
          } else {
            toast.error(craetedata.message, {
              autoClose: 2000,
              transition: Slide,
            });
          }
        } else {
          toast.error("Already Attendance added today", {
            autoClose: 2000,
            transition: Slide,
          });
        }
      } else {
        var craetedata1 = await addAttendance({ id: adminid, status: false });
        if (craetedata1.message === "SUCCESS") {
          toast.success("Attendance added...", {
            autoClose: 2000,
            transition: Slide,
          });
          GetBatchInfo();
        } else {
          toast.error(craetedata1.message, {
            autoClose: 2000,
            transition: Slide,
          });
        }
      }
    }
  };

  function closemodel() {
    setnewmodel(false);
  }

  const moveStudent = async (data) => {
    if (confirm("Are you sure you want to move these students?")) {
      const removeUsers = (usersList, removeOption) => {
        return removeOption === "all"
          ? []
          : usersList.filter((user) => ![].concat(removeOption).includes(user));
      };
      const currentUsers = batchdata[0].users
        ? batchdata[0].users.split(",").map(Number)
        : [];

      const removeuser = removeUsers(currentUsers, selectedItems);

      const obj = {
        id: batchdata[0].id,
        users: removeuser.length > 0 ? removeuser.toString() : "",
      };
      const combined = [
        ...new Set([
          ...data.users.split(",").map(Number).filter(Boolean),
          ...selectedItems.filter(Boolean),
        ]),
      ].join(",");
      const newobj = {
        id: data.id,
        users: combined,
      };
      await UpdateBatch(obj);
      await UpdateBatch(newobj);
      setSelectedItems([]);
      GetBatchInfo();
      setTimeout(() => {
        setnewmodel(false);
      }, 2000);
    }
  };
  const selectAll = () => {
    setSelectedItems(
      selectall == false
        ? batchdata[0].users.length !== 0
          ? batchdata[0].users.split(",").map(Number)
          : []
        : []
    );
    setselectall(!selectall);
  };

  return (
    <>
      <div className="attendance-report-container px-8">
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => {
            navigate(`/batchlist`);
          }}
        >
          <WestIcon />
          <h2 className="text-2xl font-black capitalize">Batch Information</h2>
        </div>
        <div className="mt-5 flex gap-4">
          <button
            className={
              module == "Modules"
                ? "bg-black py-2 px-8 rounded text-white font-medium"
                : "bg-white border border-black py-2 px-8 rounded text-black font-medium"
            }
            onClick={() => {
              setmodule("Modules");
            }}
          >
            Modules
          </button>
          <button
            className={
              module == "User List"
                ? "bg-black py-2 px-8 rounded text-white font-medium"
                : "bg-white border border-black py-2 px-8 rounded text-black font-medium"
            }
            onClick={() => {
              setmodule("User List");
            }}
          >
            User List
          </button>
        </div>
        {module == "Modules" && <Module />}
        {module == "User List" && (
          <>
            <div className="flex justify-between items-center">
              <h1 className="text-xl font-bold mt-5 text-green-600">
                User List
              </h1>
              {selectedItems.length !== 0 ? (
                <div className="flex gap-4">
                  {loading == true ? (
                    <button className="bg-black py-2 px-8 rounded text-white">
                      Please Wait...
                    </button>
                  ) : (
                    <button
                      className="bg-black py-2 px-8 rounded text-white"
                      onClick={addBulkAtt}
                    >
                      Add Bulk Attendance
                    </button>
                  )}

                  <button
                    className="bg-black py-2 px-8 rounded text-white"
                    onClick={() => {
                      setnewmodel(true);
                    }}
                  >
                    Move Students
                  </button>
                </div>
              ) : (
                <div className="flex gap-4">
                  <button className="bg-gray-400 py-2 px-8 rounded text-white cursor-not-allowed">
                    Add Bulk Attendance
                  </button>
                  <button className="bg-gray-400 py-2 px-8 rounded text-white cursor-not-allowed">
                    Move Students
                  </button>
                </div>
              )}
            </div>
            <hr className="border border-gray-500 my-2" />
            <table className="w-full text-center mt-5">
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Student Name</th>
                  <th>Email</th>
                  <th>Joining Date</th>
                  <th>Location</th>
                  <th>
                    Select{" "}
                    <input
                      className="mr-2"
                      type="checkbox"
                      onChange={selectAll}
                    />
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userlistData.length !== 0 ? (
                  userlistData.map((data, index) => {
                    const attendanceToday = todayattenacedata.find(
                      (entry) => entry.student == data.id
                    );
                    const isPresent = attendanceToday
                      ? attendanceToday.status === "true"
                      : false;
                    const isAbsent = attendanceToday
                      ? attendanceToday.status === "false"
                      : false;

                    return (
                      <tr key={data.id}>
                        <td>{index + 1}</td>
                        <td>{data.name}</td>
                        <td>{data.email}</td>
                        <td>{data.joiningdate}</td>
                        <td>{data.location}</td>
                        <td>
                          <input
                            className="mr-2"
                            type="checkbox"
                            id={`checkbox-${data.id}`}
                            checked={selectedItems.includes(data.id)}
                            onChange={() => handleCheckboxChange(data.id)}
                          />
                        </td>
                        <td>
                          <div className="flex gap-2">
                            {!isAbsent && (
                              <button
                                className={`py-2 px-5 rounded ${
                                  isPresent
                                    ? "bg-black cursor-not-allowed text-white"
                                    : "bg-green-500 hover:bg-green-800 text-white"
                                }`}
                                onClick={() => Presentbtn(data.id)}
                                disabled={isPresent || isAbsent}
                              >
                                Present
                              </button>
                            )}
                            {!isPresent && (
                              <button
                                className={`py-2 px-5 rounded ${
                                  isAbsent
                                    ? "bg-black cursor-not-allowed text-white"
                                    : "bg-red-500 hover:bg-red-400 text-white"
                                }`}
                                onClick={() => Absentbtn(data.id)}
                                disabled={isPresent || isAbsent}
                              >
                                Absent
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <td colSpan={7}>No Users Available</td>
                  </>
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
      <Modal
        isOpen={newmodel}
        onRequestClose={closemodel}
        className="Movebatchmodel"
      >
        <div className="flex justify-between items-center">
          <h1>Move Students</h1>
          <IoMdCloseCircle onClick={closemodel} className="h-8 w-8" />
        </div>
        <hr className="my-2 border border-black" />
        <div className="mt-5 flex flex-col justify-center">
          {allbatchdata.length !== 0
            ? allbatchdata.map(
                (data, index) =>
                  data.id !== batchdata[0].id && (
                    <button
                      key={index}
                      className="bg-black rounded text-white py-2 px-5 mb-2"
                      onClick={() => {
                        moveStudent(data);
                      }}
                    >
                      {data.name}
                    </button>
                  )
              )
            : null}
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default BatchInfo;
