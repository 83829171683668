/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { format, parseISO, isWithinInterval } from "date-fns";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import * as XLSX from "xlsx";
import WestIcon from "@mui/icons-material/West";
import { Attendancebystaff } from "../../Api/staffattendance";

const AttendanceView = ({ setviewattendance, staffid, staffdata }) => {
  let id = staffid;
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [studentId, setStudentId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [salary, setsalary] = useState(25000);
  const [deductedSalary, setDeductedSalary] = useState(salary);
  const [allowedLeaves, setAllowedLeaves] = useState(1);

  useEffect(() => {
    GetAllData();
  }, [id, staffdata]);

  useEffect(() => {
    setTimeout(() => {
      handleGenerateReport();
    }, 3000);
  }, [attendanceData, startDate, endDate]);

  const handleGenerateReport = () => {
    if (!startDate && !endDate && !studentId) {
      alert("Please fill all fields.");
      return;
    }
    const start = new Date(startDate);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999); // Set end time to the end of the day

    const filtered = attendanceData.filter(
      (record) =>
        record.staff === parseInt(studentId) &&
        isWithinInterval(new Date(record.date), { start, end })
    );

    let present = 0;
    let absent = 0;
    let noData = 0;
    let actualAbsent = 0; // Track absences after allowance
    let currentDate = new Date(start);

    while (currentDate <= end) {
      const isSunday = currentDate.getDay() === 0;
      const isSecondSaturday =
        currentDate.getDay() === 6 &&
        currentDate.getDate() >= 8 &&
        currentDate.getDate() <= 14;

      if (isSunday || isSecondSaturday) {
        noData++;
      } else {
        const hasRecord = filtered.some(
          (record) =>
            new Date(record.date).toDateString() === currentDate.toDateString()
        );

        if (hasRecord) {
          absent++;
        } else {
          present++;
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    actualAbsent = Math.max(0, absent - allowedLeaves);
    setPresentCount(present);
    setAbsentCount(absent);

    const workingDays = present + actualAbsent + noData;
    const dailyWage = salary / workingDays;
    setDeductedSalary(salary - actualAbsent * dailyWage);
    setFilteredData(filtered);
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const formattedDate = format(date, "yyyy-MM-dd");
      const today = new Date();
      const isSunday = date.getDay() === 0;
      const isSecondSaturday =
        date.getDay() === 6 && date.getDate() >= 8 && date.getDate() <= 14;
      const attendanceRecord = filteredData.find((record) => {
        if (record.date) {
          try {
            const recordDate = parseISO(moment(record.date).format());
            return format(recordDate, "yyyy-MM-dd") === formattedDate;
          } catch (error) {
            console.error("Error parsing date:", record.date, error);
          }
        }
        return false;
      });

      if (attendanceRecord) {
        return "absent";
      } else if (isSunday || isSecondSaturday) {
        return "no-data";
      } else if (date <= today) {
        return "present";
      }
    }
    return null;
  };

  const GetAllData = async () => {
    setStudentId(id);
    setsalary(staffdata[0].salary);
    const studentAttendance = await Attendancebystaff({ id });
    setAttendanceData(studentAttendance);
  };

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      alert("No data available to export.");
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999);

    const exportData = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      const dateString = moment(currentDate).format("YYYY-MM-DD");
      const attendanceRecord = filteredData.find((record) => {
        const recordDate = new Date(record.date);
        return (
          record.staff === parseInt(studentId) &&
          recordDate.toDateString() === currentDate.toDateString()
        );
      });

      const isSunday = currentDate.getDay() === 0;
      const isSecondSaturday =
        currentDate.getDay() === 6 &&
        currentDate.getDate() >= 8 &&
        currentDate.getDate() <= 14;

      // Determine attendance status
      const status = attendanceRecord
        ? "Absent"
        : isSunday || isSecondSaturday
        ? "No Data"
        : "Present";

      exportData.push({
        Date: dateString,
        Status: status,
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Calculate the total salary deductions and present days
    const totalPresentDays = presentCount;
    const deductedSalary =
      salary -
      Math.max(0, absentCount - allowedLeaves) *
        (salary / (totalPresentDays + absentCount));

    // Add summary data to the export
    exportData.push({
      Date: "Total",
      Status: `Present Days: ${totalPresentDays}, Salary Deducted: ₹${(
        salary - deductedSalary
      ).toFixed(2)}`,
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Attendance Report");
    XLSX.writeFile(
      wb,
      `Attendance_Report_${studentId}_${moment().format("YYYY-MM-DD")}.xlsx`
    );
  };

  return (
    <div className="attendance-report-container">
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={() => {
          setviewattendance(false);
        }}
      >
        <WestIcon />
        <h1 className="text-2xl font-black">
          {staffdata.length !== 0
            ? `${staffdata[0].firstname} ${staffdata[0].lastname}`
            : "Staff"}{" "}
          Attendance Details
        </h1>
      </div>

      <div className="flex gap-4 items-center mt-5">
        <div className="form-group">
          <label>Start Date: </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="input-field border-2 border-gray-500 rounded p-2"
          />
        </div>

        <div className="form-group">
          <label>End Date: </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="input-field border-2 border-gray-500 rounded p-2"
          />
        </div>

        <button onClick={handleGenerateReport} className="generate-button">
          Search
        </button>
        {filteredData.length !== 0 && (
          <button onClick={exportToExcel} className="generate-button">
            Export to Excel
          </button>
        )}
      </div>

      <div className="flex justify-between gap-4 mt-5">
        <div className="card present-card">
          <h3>Present Days</h3>
          <p>{presentCount}</p>
        </div>
        <div className="card absent-card">
          <h3>Absent Days</h3>
          <p>{absentCount}</p>
        </div>
        <div className="card salary-card">
          <h3>This Month Salary</h3>
          <p>{`₹${Number(salary).toFixed(2)}`}</p>
        </div>
        <div className="card salary-card">
          <h3>Deducted Salary</h3>
          <p>{(Number(salary) - Number(deductedSalary)).toFixed(2)}</p>
        </div>
        <div className="card allowed-leaves-card">
          <h3>Available Leaves</h3>
          <p>{Math.max(0, allowedLeaves - absentCount)}</p>
        </div>
      </div>

      <div className="calendar-container mt-5">
        <Calendar
          onChange={setStartDate}
          value={new Date(startDate)}
          tileClassName={tileClassName}
        />
      </div>
      <style jsx>{`
        .attendance-report-container {
          max-width: 1200px;
          margin: 0 30px;
          padding: 10px;
        }

        .form-group {
          margin: 15px 0;
          display: grid;
        }

        .input-field {
          padding: 10px;
          font-size: 16px;
          width: 250px;
        }

        .generate-button {
          padding: 15px 20px;
          background-color: black;
          color: white;
          border: none;
          cursor: pointer;
          margin-top: 20px;
          border-radius: 5px;
        }

        .generate-button:hover {
          background-color: #0056b3;
        }

        .calendar-container {
          margin-top: 30px;
          width: 1180px;
        }
        .react-calendar {
          width: 100%;
          max-width: 100%;
          background: white;
          border: 1px solid #a0a096;
          font-family: Arial, Helvetica, sans-serif;
          line-height: 1.125em;
        }
        .full-width-calendar {
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
        }

        .present {
          background-color: #49c767 !important;
          color: white !important;
        }

        .absent {
          background-color: #ac4242 !important;
          color: white !important;
        }

        .no-data {
          background-color: grey !important;
          color: white !important;
        }

        .react-calendar button {
          margin: 0;
          border: 0;
          outline: none;
          height: 90px;
        }
        .react-calendar__navigation {
          display: flex;
          height: 90px;
          margin-bottom: 1em;
          background-color: black;
          color: white;
          font-weight: 800;
          justify-content: center;
          align-items: center;
        }
        .react-calendar__navigation:hover {
          display: flex;
          height: 90px;
          margin-bottom: 1em;
          background-color: black;
          color: white;
          font-weight: 800;
          justify-content: center;
          align-items: center;
        }
        .react-calendar__navigation button:enabled:hover {
          background-color: black;
        }

        .card {
          padding: 20px;
          background-color: #f5f5f5;
          border: 1px solid #ddd;
          border-radius: 5px;
          width: 30%;
          text-align: center;
        }

        .present-card {
          background-color: #d4edda;
          color: #155724;
        }

        .absent-card {
          background-color: #f8d7da;
          color: #721c24;
        }

        .no-data-card {
          background-color: #ececec;
          color: #6c757d;
        }

        @media (max-width: 768px) {
          .full-width-calendar {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default AttendanceView;
