/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import * as XLSX from "xlsx";
import WestIcon from "@mui/icons-material/West";
import { viewallstaff } from "../../Api/staff";
import { IconButton } from "@mui/material";
import { IoIosInformationCircle } from "react-icons/io";
import AttendanceView from "./AttendanceView";
import { SendInvoice } from "../../Api/invoie";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StaffattendaceReport = ({ setreportView }) => {
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [attendanceData, setAttendanceData] = useState([]);
  const [finaldata, setFinaldata] = useState([]);
  const [click, setclick] = useState(false);
  const [viewattendance, setviewattendance] = useState(false);
  const [staffid, setstaffid] = useState(null);
  const [staffdata, setstaffdata] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    GetAllData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      exportToExcel();
    }, 3000);
  }, [attendanceData, startDate, endDate]);

  const GetAllData = async () => {
    const alldata = await viewallstaff();
    setAttendanceData(alldata);
  };

  const exportToExcel = () => {
    if (attendanceData.length !== 0) {
      var finaldata = [];
      for (var i = 0; i < attendanceData.length; i++) {
        var filteredData = attendanceData[i].StaffAttendances;
        const start = new Date(startDate);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);
        const exportData = [];
        let currentDate = new Date(start);

        while (currentDate <= end) {
          const dateString = moment(currentDate).format("YYYY-MM-DD");
          const attendanceRecord = filteredData.find((record) => {
            const recordDate = new Date(record.date);
            return recordDate.toDateString() === currentDate.toDateString();
          });
          const isSunday = currentDate.getDay() === 0;
          const isSecondSaturday =
            currentDate.getDay() === 6 &&
            currentDate.getDate() >= 8 &&
            currentDate.getDate() <= 14;
          const status = attendanceRecord
            ? "Absent"
            : isSunday || isSecondSaturday
            ? "No Data"
            : "Present";

          exportData.push({
            Date: dateString,
            Status: status,
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }
        var presentdata = exportData.filter((data) => {
          return data.Status == "Present" || data.Status == "No Data";
        });
        var absentdata = exportData.filter((data) => {
          return data.Status == "Absent";
        });
        const totalPresentDays = presentdata.length;
        const deductedSalary =
          attendanceData[i].salary -
          Math.max(0, absentdata.length - 2) *
            (attendanceData[i].salary / (totalPresentDays + absentdata.length));
        exportData.push({
          Date: "Total",
          Status: `Present Days: ${totalPresentDays}, Salary Deducted: ₹${(
            attendanceData[i].salary - deductedSalary
          ).toFixed(2)}`,
        });
        finaldata.push({
          S_no: i + 1,
          Name: `${attendanceData[i].firstname} ${attendanceData[i].lastname}`,
          Email: attendanceData[i].email,
          Phone: attendanceData[i].phone,
          Present: totalPresentDays,
          Absent: absentdata.length,
          Salary: Number(attendanceData[i].salary).toFixed(2),
          RemainingSalary: Number(deductedSalary).toFixed(2),
          SalaryDeducted: (attendanceData[i].salary - deductedSalary).toFixed(
            2
          ),
          id: attendanceData[i].id,
          data: attendanceData[i],
        });
      }
      setFinaldata(finaldata);
      if (click == true) {
        const ws = XLSX.utils.json_to_sheet(finaldata);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Staff Attendance Report");

        XLSX.writeFile(
          wb,
          `Staff_Attendance_Report_${moment().format("MMMM")}.xlsx`
        );
      }
    }
  };
  const calculateTotals = () => {
    let totalPresent = 0;
    let totalAbsent = 0;
    let totalSalary = 0;
    let totalSalaryDeducted = 0;
    let totalRemainingSalary = 0;

    finaldata.forEach((item) => {
      totalPresent += item.Present;
      totalAbsent += item.Absent;
      totalSalary += Number(item.Salary);
      totalSalaryDeducted += Number(item.SalaryDeducted);
      totalRemainingSalary += Number(item.RemainingSalary);
    });

    return {
      totalPresent,
      totalAbsent,
      totalSalary,
      totalSalaryDeducted,
      totalRemainingSalary,
    };
  };
  const totals = calculateTotals();
  const sendInvoice = async () => {
    if (finaldata.length !== 0) {
      setloading(true);
      for (var i = 0; i < finaldata.length; i++) {
        var obj = {
          id: i + 1,
          name: finaldata[i].Name,
          email: finaldata[i].Email,
          phone: finaldata[i].Phone,
          salary: finaldata[i].Salary,
          deductedSalary: finaldata[i].SalaryDeducted,
          remainingSalary: finaldata[i].RemainingSalary,
          month: moment(startDate).format("MMMM"),
        };
        await SendInvoice(obj);
      }
      toast.success("Invoice send Successfully...", {
        autoClose: 2000,
        transition: Slide,
      });
      setloading(false);
    }
  };
  return (
    <>
      {viewattendance == false ? (
        <div className="attendance-report-container">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setreportView(false);
            }}
          >
            <WestIcon />
            <h1 className="text-2xl py-4 font-black">
              {" "}
              Staff Attendance Report
            </h1>
          </div>

          <div className="flex gap-4 items-center mt-5">
            <div className="form-group">
              <label>Start Date: </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="input-field border-2 border-gray-500 rounded p-2"
              />
            </div>

            <div className="form-group">
              <label>End Date: </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="input-field border-2 border-gray-500 rounded p-2"
              />
            </div>

            <button
              onClick={() => {
                setclick(true);
                exportToExcel();
              }}
              className="generate-button"
            >
              Export to Excel
            </button>
            {loading == true ? (
              <button className="generate-button">Please Wait...</button>
            ) : (
              <button onClick={sendInvoice} className="generate-button">
                Send Invoice
              </button>
            )}
          </div>
          {finaldata.length > 0 && (
            <div className="mt-5">
              <h2 className="text-xl font-semibold">Attendance Summary</h2>
              <table className="min-w-full border-collapse border border-gray-300 mt-2">
                <thead>
                  <tr>
                    <th className="border border-gray-300 p-2 text-sm">S.No</th>
                    <th className="border border-gray-300 p-2 text-sm">Name</th>
                    <th className="border border-gray-300 p-2 text-sm">
                      Email
                    </th>
                    <th className="border border-gray-300 p-2 text-sm">
                      Phone
                    </th>
                    <th className="border border-gray-300 p-2 text-sm">
                      Present
                    </th>
                    <th className="border border-gray-300 p-2 text-sm">
                      Absent
                    </th>
                    <th className="border border-gray-300 p-2 text-sm">
                      Salary
                    </th>
                    <th className="border border-gray-300 p-2 text-sm">
                      Salary Deducted
                    </th>
                    <th className="border border-gray-300 p-2 text-sm">
                      Remaining Salary
                    </th>
                    <th className="border border-gray-300 p-2 text-sm">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {finaldata.map((item) => (
                    <tr key={item.S_no}>
                      <td className="border border-gray-300 p-2 text-sm">
                        {item.S_no}
                      </td>
                      <td className="border border-gray-300 p-2 text-sm">
                        {item.Name}
                      </td>
                      <td className="border border-gray-300 p-2 text-sm">
                        {item.Email}
                      </td>
                      <td className="border border-gray-300 p-2 text-sm">
                        {item.Phone}
                      </td>
                      <td className="border border-gray-300 p-2 text-sm">
                        {item.Present}
                      </td>
                      <td className="border border-gray-300 p-2 text-sm">
                        {item.Absent}
                      </td>
                      <td className="border border-gray-300 p-2 text-sm">
                        {item.Salary}
                      </td>
                      <td className="border border-gray-300 p-2 text-sm">
                        {item.SalaryDeducted}
                      </td>
                      <td className="border border-gray-300 p-2 text-sm">
                        {item.RemainingSalary}
                      </td>
                      <td className="border border-gray-300 p-2 text-sm">
                        <IconButton>
                          <IoIosInformationCircle
                            onClick={() => {
                              setviewattendance(true);
                              setstaffid(item.id);
                              setstaffdata([item.data]);
                            }}
                          />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      className="border border-gray-300 p-2 text-sm font-bold"
                      colSpan={6}
                    >
                      Totals:
                    </td>
                    {/* <td className="border border-gray-300 p-2 text-sm font-bold">
                      {totals.totalPresent}
                    </td> */}
                    {/* <td className="border border-gray-300 p-2 text-sm font-bold">
                      {totals.totalAbsent}
                    </td> */}
                    <td className="border border-gray-300 p-2 text-sm font-bold">
                      {totals.totalSalary.toFixed(2)}
                    </td>
                    <td className="border border-gray-300 p-2 text-sm font-bold">
                      {totals.totalSalaryDeducted.toFixed(2)}
                    </td>
                    <td
                      className="border border-gray-300 p-2 text-sm font-bold"
                      colSpan={2}
                    >
                      {totals.totalRemainingSalary.toFixed(2)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
          {finaldata.length === 0 && (
            <h6 className="flex items-center justify-center mt-36">
              Please Wait..
            </h6>
          )}

          <style jsx>{`
            .attendance-report-container {
              max-width: 1200px;
              margin: 0 30px;
              padding: 10px;
            }

            .form-group {
              margin: 15px 0;
              display: grid;
            }

            .input-field {
              padding: 10px;
              font-size: 16px;
              width: 250px;
            }

            .generate-button {
              padding: 15px 20px;
              background-color: black;
              color: white;
              border: none;
              cursor: pointer;
              margin-top: 20px;
              border-radius: 5px;
            }

            .generate-button:hover {
              background-color: #0056b3;
            }

            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }

            th {
              background-color: #f0f0f0;
              text-align: left;
            }

            td {
              text-align: left;
            }

            @media (max-width: 768px) {
              .full-width-calendar {
                width: 100%;
              }
            }
          `}</style>
        </div>
      ) : (
        <AttendanceView
          setviewattendance={setviewattendance}
          staffid={staffid}
          staffdata={staffdata}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default StaffattendaceReport;
