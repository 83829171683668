/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { format, parseISO, isWithinInterval } from "date-fns";
import "react-calendar/dist/Calendar.css";
import { useParams } from "react-router-dom";
import { AttendancebyStudent } from "../Api/attendance";
import moment from "moment";
import * as XLSX from "xlsx";

const AttendanceReport = () => {
  let { id } = useParams();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [studentId, setStudentId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);

  // New state variables for the counts
  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [noDataCount, setNoDataCount] = useState(0);

  const handleGenerateReport = () => {
    if (!startDate || !endDate || !studentId) {
      alert("Please fill all fields.");
      return;
    }

    // Parse the start and end dates
    const start = new Date(startDate);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999); // Set end time to the end of the day

    const filtered = attendanceData.filter(
      (record) =>
        record.student === parseInt(studentId) &&
        isWithinInterval(new Date(record.date), {
          start: start,
          end: end,
        })
    );

    // Calculate present, absent, and no data counts
    let present = 0;
    let absent = 0;
    let noData = 0;

    // Calculate no data days (Sundays and second Saturdays in the filtered range)
    let currentDate = new Date(start);
    while (currentDate <= end) {
      const isSunday = currentDate.getDay() === 0;
      const isSecondSaturday =
        currentDate.getDay() === 6 &&
        currentDate.getDate() >= 8 &&
        currentDate.getDate() <= 14;

      if (isSunday || isSecondSaturday) {
        noData++;
      }

      // Check if there's an attendance record for the current date
      const hasRecord = filtered.some((record) => {
        const recordDate = new Date(record.date);
        return (
          record.student === parseInt(studentId) &&
          recordDate.toDateString() === currentDate.toDateString()
        );
      });

      if (hasRecord) {
        const record = filtered.find(
          (rec) =>
            new Date(rec.date).toDateString() === currentDate.toDateString() &&
            rec.student === parseInt(studentId)
        );

        if (record && record.status == "true") {
          present++;
        } else {
          absent++;
        }
      } else if (!isSunday && !isSecondSaturday) {
        absent++; // Mark days with no record as absent unless it's Sunday or second Saturday
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Update the counts to state
    setPresentCount(present);
    setAbsentCount(absent);
    setNoDataCount(noData);
    setFilteredData(filtered);
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const formattedDate = format(date, "yyyy-MM-dd");
      const today = new Date();

      // Find the attendance record for the specific date
      const attendanceRecord = filteredData.find((record) => {
        if (record.date) {
          try {
            const recordDate = parseISO(moment(record.date).format());
            if (!isNaN(recordDate)) {
              return format(recordDate, "yyyy-MM-dd") === formattedDate;
            }
          } catch (error) {
            console.error("Error parsing date:", record.date, error);
          }
        }
        return false;
      });

      // Handle special days: Sundays and second Saturdays
      const isSunday = date.getDay() === 0;
      const isSecondSaturday =
        date.getDay() === 6 && date.getDate() >= 8 && date.getDate() <= 14;

      const isPastOrToday = date <= today;

      if (!isPastOrToday) {
        // Don't apply any styling to future dates
        return null;
      }

      // Return class names based on status
      if (attendanceRecord) {
        return attendanceRecord.status == "true" ? "present" : "absent";
      } else if (isSunday || isSecondSaturday) {
        return "no-data"; // Yellow color for no data (Sundays and second Saturdays)
      } else {
        return "absent"; // Mark other days as absent if no record exists
      }
    }
    return null;
  };

  useEffect(() => {
    GetAllData();
  }, [id]);

  const GetAllData = async () => {
    setStudentId(id);
    const studentAttendance = await AttendancebyStudent({ id: id });
    setAttendanceData(studentAttendance);
  };

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      alert("No data available to export.");
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999); // Set end time to the end of the day

    // Create an array to hold all the dates with their statuses
    const exportData = [];

    let currentDate = new Date(start);
    while (currentDate <= end) {
      const dateString = moment(currentDate).format("YYYY-MM-DD");

      // Find the attendance record for the current date
      const attendanceRecord = filteredData.find((record) => {
        const recordDate = new Date(record.date);
        return (
          record.student === parseInt(studentId) &&
          recordDate.toDateString() === currentDate.toDateString()
        );
      });

      // Add a record to the export data
      if (attendanceRecord) {
        exportData.push({
          Date: dateString,
          Status: attendanceRecord.status == "true" ? "Present" : "Absent", // Assuming status is a boolean
        });
      } else {
        // Check for Sundays and second Saturdays
        const isSunday = currentDate.getDay() === 0;
        const isSecondSaturday =
          currentDate.getDay() === 6 &&
          currentDate.getDate() >= 8 &&
          currentDate.getDate() <= 14;

        exportData.push({
          Date: dateString,
          Status: isSunday || isSecondSaturday ? "No Data" : "Absent",
        });
      }

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Attendance Report");

    // Generate buffer and download
    XLSX.writeFile(
      wb,
      `Attendance_Report_${studentId}_${moment().format("YYYY-MM-DD")}.xlsx`
    );
  };
  return (
    <div className="attendance-report-container">
      <h2 className="text-2xl font-black capitalize">
        Student Attendance Calendar
      </h2>

      <div className="flex gap-4 items-center mt-5">
        <div className="form-group">
          <label>Start Date: </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="input-field border-2 border-gray-500 rounded p-2"
          />
        </div>

        <div className="form-group">
          <label>End Date: </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="input-field border-2 border-gray-500 rounded p-2"
          />
        </div>

        <button onClick={handleGenerateReport} className="generate-button">
          Search
        </button>
        {filteredData.length !== 0 && (
          <button onClick={exportToExcel} className="generate-button">
            Export to Excel
          </button>
        )}
      </div>

      {/* Display the two cards above the calendar */}
      <div className="flex justify-between gap-4 mt-5">
        <div className="card present-card">
          <h3>Present Days</h3>
          <p>{presentCount}</p>
        </div>
        <div className="card absent-card">
          <h3>Absent Days</h3>
          <p>{absentCount}</p>
        </div>
        <div className="card no-data-card">
          <h3>No Data Days</h3>
          <p>{noDataCount}</p>
        </div>
      </div>

      {filteredData.length > 0 && (
        <div className="calendar-container">
          <Calendar
            tileClassName={tileClassName}
            className="full-width-calendar"
          />
        </div>
      )}

      <style jsx>{`
        .attendance-report-container {
          max-width: 1200px;
          margin: 0 30px;
          padding: 10px;
        }

        .form-group {
          margin: 15px 0;
          display: grid;
        }

        .input-field {
          padding: 10px;
          font-size: 16px;
          width: 250px;
        }

        .generate-button {
          padding: 15px 20px;
          background-color: black;
          color: white;
          border: none;
          cursor: pointer;
          margin-top: 20px;
          border-radius: 5px;
        }

        .generate-button:hover {
          background-color: #0056b3;
        }

        .calendar-container {
          margin-top: 30px;
          width: 1180px;
        }

        .full-width-calendar {
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
        }

        .present {
          background-color: #49c767 !important;
          color: white !important;
        }

        .absent {
          background-color: #ac4242 !important;
          color: white !important;
        }

        .no-data {
          background-color: grey !important;
          color: white !important;
        }

        .react-calendar button {
          margin: 0;
          border: 0;
          outline: none;
          height: 90px;
        }
        .react-calendar__navigation {
          display: flex;
          height: 90px;
          margin-bottom: 1em;
          background-color: black;
          color: white;
          font-weight: 800;
          justify-content: center;
          align-items: center;
        }
        .react-calendar__navigation:hover {
          display: flex;
          height: 90px;
          margin-bottom: 1em;
          background-color: black;
          color: white;
          font-weight: 800;
          justify-content: center;
          align-items: center;
        }
        .react-calendar__navigation button:enabled:hover {
          background-color: black;
        }

        .card {
          padding: 20px;
          background-color: #f5f5f5;
          border: 1px solid #ddd;
          border-radius: 5px;
          width: 30%;
          text-align: center;
        }

        .present-card {
          background-color: #d4edda;
          color: #155724;
        }

        .absent-card {
          background-color: #f8d7da;
          color: #721c24;
        }

        .no-data-card {
          background-color: #ececec;
          color: #6c757d;
        }

        @media (max-width: 768px) {
          .full-width-calendar {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default AttendanceReport;
