/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Coverimg from "../Assets/ui-path.webp";
import { HiOutlineClock } from "react-icons/hi";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { viewAllBatchByUserId } from "../Api/batch";
import StudentModule from "../Components/Batch/StudentModule";

const Mycourse = () => {
  const [mydata, setmydata] = useState([]);
  const [viewoption, setviewoption] = useState(false);
  const [viewid, setviewid] = useState(null);
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");
  useEffect(() => {
    GetBatchInfo();
  }, []);
  const GetBatchInfo = async () => {
    var mydata = await viewAllBatchByUserId({ id: adminid });
    setmydata(mydata);
  };
  return (
    <div className="px-14 pb-5">
      {viewoption == false ? (
        <>
          <div className="flex justify-between items-center">
            <h1 className="text-2xl py-4 font-black">My Course</h1>
          </div>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
            {mydata.length !== 0 && (
              <div
                className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl cursor-pointer"
                onClick={() => {
                  setviewoption(true);
                  setviewid(mydata[0].id);
                }}
              >
                <img
                  src={
                    mydata[0].type !== null && mydata[0].type.icon.length !== 0
                      ? mydata[0].type.icon
                      : Coverimg
                  }
                  alt="Logo"
                  className="rounded-t-xl h-56"
                />
                <div className="px-3 pb-5">
                  <h1 className="text-lg py-2 font-bold">
                    {mydata[0].type !== null && mydata[0].type.title}
                  </h1>
                  <div className="flex gap-4">
                    <div className="flex gap-1 items-center">
                      <HiOutlineClock className="h-5 text-xl text-gray-500" />
                      <p className="text-md text-gray-500">
                        {mydata[0].type !== null && mydata[0].type.duration} Hrs
                      </p>
                    </div>
                    <div className="flex gap-1 items-center">
                      <AiOutlineGlobal className="h-5 text-xl text-gray-500" />
                      <p className="text-md text-gray-500">English</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <StudentModule id={viewid} setviewoption={setviewoption} />
      )}
    </div>
  );
};

export default Mycourse;
