/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ViewByidBatch } from "../../Api/batch";
import { ViewCoursesInfo } from "../../Api/courses";
import WestIcon from "@mui/icons-material/West";

const StudentModule = ({ id, setviewoption }) => {
  const [moduledata, setmoduledata] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    GetCourseInfo();
  }, [id]);

  const GetCourseInfo = async () => {
    const batchResponse = await ViewByidBatch({ id: id });
    if (batchResponse.length !== 0) {
      setloading(true);
      const batch = batchResponse[0];

      const completedModules = batch.completed_module
        ? batch.completed_module.split(",")
        : [];
      if (completedModules.join(",") !== selectedItems.join(",")) {
        setSelectedItems(completedModules);
      }

      const courseResponse = await ViewCoursesInfo({ id: batch.type });
      if (courseResponse.length !== 0) {
        const course = courseResponse[0];
        if (course.modules.length !== 0 && course.modules !== moduledata) {
          setmoduledata(course.modules);
          setloading(false);
        }
      }
    }
  };

  return (
    <div>
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={() => {
          setviewoption(false);
        }}
      >
        <WestIcon />
        <h1 className="text-2xl py-4 font-black">Course Modules</h1>
      </div>
      <table className="w-full text-center mt-5">
        <thead>
          <tr>
            <th>S.no</th>
            <th>Module Name</th>
            <th>Syllabus</th>
            <th>Status</th>
            <th>Task</th>
          </tr>
        </thead>
        <tbody>
          {loading == false ? (
            moduledata.length !== 0 ? (
              moduledata.map((data, index) => (
                <tr key={data.id}>
                  <td>{index + 1}</td>
                  <td>{data.title}</td>
                  <td>
                    <ul className="list-disc pl-5 text-start">
                      {data.lesson_data.length !== 0
                        ? data.lesson_data.map((item, lessonIndex) => (
                            <li key={lessonIndex}>{item.title}</li>
                          ))
                        : "No lessons available"}
                    </ul>
                  </td>
                  <td>
                    {selectedItems.includes(String(data.id))
                      ? "Completed"
                      : "Upcoming"}
                  </td>
                  <td>
                    {data.task.length !== 0 ? (
                      <button
                        className="bg-blue-500 text-white p-2 rounded"
                        onClick={() => window.open(data.task, "__blank")}
                      >
                        View
                      </button>
                    ) : (
                      "No task available"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <>
                <td colSpan={7}>No Users Available</td>
              </>
            )
          ) : (
            <>
              <td colSpan={7}>Please Wait...</td>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StudentModule;
