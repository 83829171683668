/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import * as XLSX from "xlsx";
import WestIcon from "@mui/icons-material/West";
import { ViewAllStudents } from "../Api/student";

const StudentAttendanceReport = ({ setreportStatus }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(() => {
    GetAllData();
  }, []);

  const GetAllData = async () => {
    const alldata = await ViewAllStudents();
    var allstudent = await alldata.filter((data) => {
      return data.batch == "Current";
    });
    setAttendanceData(allstudent);
  };

  const exportToExcel = () => {
    if (attendanceData.length !== 0) {
      var finaldata = [];
      for (var i = 0; i < attendanceData.length; i++) {
        var filteredData = attendanceData[i].Attendances;
        const start = new Date(startDate);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);
        const exportData = [];
        let currentDate = new Date(start);
        while (currentDate <= end) {
          const dateString = moment(currentDate).format("YYYY-MM-DD");
          const attendanceRecord = filteredData.find((record) => {
            const recordDate = new Date(record.date);
            return recordDate.toDateString() === currentDate.toDateString();
          });

          if (attendanceRecord) {
            exportData.push({
              Date: dateString,
              Status: attendanceRecord.status == "true" ? "Present" : "Absent", // Assuming status is a boolean
            });
          } else {
            const isSunday = currentDate.getDay() === 0;
            const isSecondSaturday =
              currentDate.getDay() === 6 &&
              currentDate.getDate() >= 8 &&
              currentDate.getDate() <= 14;
            exportData.push({
              Date: dateString,
              Status: isSunday || isSecondSaturday ? "No Data" : "Absent",
            });
          }

          currentDate.setDate(currentDate.getDate() + 1);
        }
        var presentdata = exportData.filter((data) => {
          return data.Status == "Present";
        });
        var absentdata = exportData.filter((data) => {
          return data.Status == "Absent";
        });
        finaldata.push({
          S_no: i + 1,
          Name: attendanceData[i].name,
          Email: attendanceData[i].email,
          Course: attendanceData[i].course,
          Phone: attendanceData[i].phone,
          Present: presentdata.length,
          Absent: absentdata.length,
        });
      }
      const ws = XLSX.utils.json_to_sheet(finaldata);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Attendance Report");

      XLSX.writeFile(
        wb,
        `Attendance_Report_${moment().format("YYYY-MM-DD")}.xlsx`
      );
    }
  };
  return (
    <div className="attendance-report-container">
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={() => {
          setreportStatus(false);
        }}
      >
        <WestIcon />
        <h1 className="text-2xl py-4 font-black"> Student Attendance Report</h1>
      </div>

      <div className="flex gap-4 items-center mt-5">
        <div className="form-group">
          <label>Start Date: </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="input-field border-2 border-gray-500 rounded p-2"
          />
        </div>

        <div className="form-group">
          <label>End Date: </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="input-field border-2 border-gray-500 rounded p-2"
          />
        </div>

        <button onClick={exportToExcel} className="generate-button">
          Export to Excel
        </button>
      </div>

      <style jsx>{`
        .attendance-report-container {
          max-width: 1200px;
          margin: 0 30px;
          padding: 10px;
        }

        .form-group {
          margin: 15px 0;
          display: grid;
        }

        .input-field {
          padding: 10px;
          font-size: 16px;
          width: 250px;
        }

        .generate-button {
          padding: 15px 20px;
          background-color: black;
          color: white;
          border: none;
          cursor: pointer;
          margin-top: 20px;
          border-radius: 5px;
        }

        .generate-button:hover {
          background-color: #0056b3;
        }

        .calendar-container {
          margin-top: 30px;
          width: 1180px;
        }

        .full-width-calendar {
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
        }

        .present {
          background-color: #49c767 !important;
          color: white !important;
        }

        .absent {
          background-color: #ac4242 !important;
          color: white !important;
        }

        .no-data {
          background-color: grey !important;
          color: white !important;
        }

        .react-calendar button {
          margin: 0;
          border: 0;
          outline: none;
          height: 90px;
        }
        .react-calendar__navigation {
          display: flex;
          height: 90px;
          margin-bottom: 1em;
          background-color: black;
          color: white;
          font-weight: 800;
          justify-content: center;
          align-items: center;
        }
        .react-calendar__navigation:hover {
          display: flex;
          height: 90px;
          margin-bottom: 1em;
          background-color: black;
          color: white;
          font-weight: 800;
          justify-content: center;
          align-items: center;
        }
        .react-calendar__navigation button:enabled:hover {
          background-color: black;
        }

        .card {
          padding: 20px;
          background-color: #f5f5f5;
          border: 1px solid #ddd;
          border-radius: 5px;
          width: 30%;
          text-align: center;
        }

        .present-card {
          background-color: #d4edda;
          color: #155724;
        }

        .absent-card {
          background-color: #f8d7da;
          color: #721c24;
        }

        .no-data-card {
          background-color: #ececec;
          color: #6c757d;
        }

        @media (max-width: 768px) {
          .full-width-calendar {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default StudentAttendanceReport;
