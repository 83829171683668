import { Batch } from "../axios";

export const createBatch = async (data) => {
  var createBatch = await Batch.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createBatch;
};

export const ViewByidBatch = async (data) => {
  var ViewByidBatch = await Batch.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return ViewByidBatch;
};

export const UpdateBatch = async (data) => {
  var UpdateBatch = await Batch.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return UpdateBatch;
};

export const DestroyBatch = async (data) => {
  var DestroyBatch = await Batch.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return DestroyBatch;
};

export const ViewallBatch = async (data) => {
  var ViewallBatch = await Batch.post(`/viewallbatch`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return ViewallBatch;
};

export const viewAllBatchByUserId = async (data) => {
  var viewAllBatchByUserId = await Batch.post(`/viewAllBatchByUserId`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewAllBatchByUserId;
};
