/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-self-compare */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { viewallExpense } from "../Api/expense";
import moment from "moment/moment";
import { FaPhoneAlt } from "react-icons/fa";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import { viewallPayment } from "../Api/payment";
import { ViewAllStudents } from "../Api/student";
import { viewallAmount_split } from "../Api/amount_split";
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const Dashboard = () => {
  const navigate = useNavigate();
  const [expenceamount, setexpenceamount] = useState(0);
  const [returnamount, setreturnamount] = useState(0);
  const [lastmonth, setlastmonth] = useState(0);
  const [outstanding, setoutstanding] = useState(0);
  const [overduedata, setoverduedata] = useState([]);
  const [allchartdata, setallchartdata] = useState([]);

  const labels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Students",
        data: allchartdata,
        borderColor: "rgb(1, 152, 117,0.5)",
        backgroundColor: "rgba(104, 195, 163,0.05)",
      },
    ],
  };

  useEffect(() => {
    getStaffdata();
  }, []);
  const getStaffdata = async () => {
    var month = moment().format("MM");
    var year = moment().format("YYYY");
    var oldmonth = moment().subtract(1, "month").format("MM");
    var alldata = await viewallExpense();
    if (alldata.length !== 0) {
      var filterdata = [],
        amount = [];
      for (var i = 0; i < alldata.length; i++) {
        if (
          alldata[i].date.split("-")[1] == month &&
          alldata[i].date.split("-")[0] == year
        ) {
          filterdata.push(alldata[i]);
          amount.push(Number(alldata[i].cost));
        }
      }
      const sum = amount.reduce((partialSum, a) => partialSum + a, 0);
      setexpenceamount(sum);
    }
    var allreturn = await viewallPayment();
    if (allreturn.length !== 0) {
      var amountnew = [],
        oldamount = [];
      for (var j = 0; j < allreturn.length; j++) {
        if (
          allreturn[j].date.split("-")[1] == month &&
          allreturn[j].date.split("-")[0] == year
        ) {
          amountnew.push(Number(allreturn[j].cost));
        }
        if (
          allreturn[j].date.split("-")[1] == oldmonth &&
          allreturn[j].date.split("-")[0] == year
        ) {
          oldamount.push(Number(allreturn[j].cost));
        }
      }
      const sumnew = amountnew.reduce((partialSum, a) => partialSum + a, 0);
      const oldnew = oldamount.reduce((partialSum, a) => partialSum + a, 0);
      setreturnamount(sumnew);
      setlastmonth(oldnew);
    }
    var allstudentdata = await ViewAllStudents();
    if (allstudentdata.length !== 0) {
      var amountnew1 = [];
      for (var k = 0; k < allstudentdata.length; k++) {
        amountnew1.push(
          Number(
            Number(allstudentdata[k].fees) - Number(allstudentdata[k].paidfees)
          )
        );
      }
      const sum = amountnew1.reduce((partialSum, a) => partialSum + a, 0);
      setoutstanding(sum);
    }
    var amountsplit = await viewallAmount_split();
    if (amountsplit.length !== 0) {
      var today = moment().format("YYYY-MM-DD");
      var otherdata = [];
      for (var a = 0; a < amountsplit.length; a++) {
        if (amountsplit[a].duedate < today) {
          otherdata.push(amountsplit[a]);
        }
      }
      setoverduedata(otherdata);
    }
  };
  function getStartOfWeekData() {
    var today = new Date();
    var dayOfWeek = today.getDay();
    var startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - dayOfWeek);
    var weekData = [];
    for (var i = 0; i < 7; i++) {
      var currentDate = new Date(startOfWeek);
      currentDate.setDate(startOfWeek.getDate() + i);
      weekData.push(currentDate);
    }
    return weekData;
  }
  var startOfWeekData = getStartOfWeekData();
  var formatDate = function (date) {
    return moment(date).format("YYYY-MM-DD");
  };
  startOfWeekData = startOfWeekData.map(function (date) {
    return formatDate(date);
  });
  useEffect(() => {
    getChartdata();
  }, []);

  const getChartdata = async () => {
    var allstudentdata = await ViewAllStudents();
    if (allstudentdata.length !== 0) {
      var mydata = [];

      for (var i = 0; i < startOfWeekData.length; i++) {
        var checkdata = allstudentdata.filter((data) => {
          if (
            startOfWeekData[i] == moment(data.createdAt).format("YYYY-MM-DD")
          ) {
            return data;
          }
        });
        mydata.push(checkdata.length);
      }
      setallchartdata(mydata);
    }
  };
  useEffect(() => {
    Geturl();
  }, []);
  const Geturl = async () => {
    var role = sessionStorage.getItem("role") || localStorage.getItem("role");
    if (role !== "admin") {
      if (role === "staff") {
        navigate("/attendance", { replace: true });
      } else {
        navigate("/mycourse", { replace: true });
      }
    }
  };
  return (
    <div>
      <div className="px-12">
        <h1 className="text-2xl py-4 font-black">Dashboard</h1>
        <div className="flex gap-8">
          <div className="h-36 p-3 flex flex-col rounded-sm justify-evenly w-80 shadow-2xl">
            <h1 className="text-xl font-black">Expenses Of The Month</h1>
            <p className="font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-red-700 to-red-400">
              {expenceamount.toLocaleString("en-IN")}
            </p>
            <button
              onClick={() => {
                navigate(`/expense`);
              }}
              className="w-full h-12 mt-3 bg-slate-900 rounded-md text-white hover:bg-white hover:duration-100 hover:text-black hover:border-2 hover:border-black"
            >
              Know More
            </button>
          </div>
          <div className="h-36 p-3 flex flex-col rounded-sm justify-evenly w-80 shadow-2xl">
            <h1 className="text-xl font-black">Return Of The Month</h1>
            <p className="font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-lime-500 to-lime-300">
              ₹ {returnamount.toLocaleString("en-IN")}
            </p>
            <button
              onClick={() => {
                navigate(`/payment`);
              }}
              className="w-full h-12 mt-3 bg-slate-900 rounded-md text-white hover:bg-white hover:duration-100 hover:text-black hover:border-2 hover:border-black"
            >
              Know More
            </button>
          </div>
          <div className="h-36 p-3 flex flex-col rounded-sm justify-evenly w-80 shadow-2xl">
            <h1 className="text-xl font-black">Outstanding Income</h1>
            <p className="font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-red-700 to-red-400">
              ₹ {Number(outstanding).toLocaleString("en-IN")}
            </p>
            {/* <button className="w-full h-12 mt-3 bg-slate-900 rounded-md text-white hover:bg-white hover:duration-100 hover:text-black hover:border-2 hover:border-black">
              Know More
            </button> */}
          </div>
          <div className="h-36 p-3 flex flex-col rounded-sm justify-evenly w-80 shadow-2xl">
            <h1 className="text-xl font-black">Growth Comparison</h1>
            <p className="font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-red-700 to-red-400">
              {Number(lastmonth) !== 0 && Number(returnamount) !== 0
                ? Number(lastmonth) > Number(returnamount)
                  ? `${Math.round(
                      ((lastmonth - returnamount) / lastmonth) * 100
                    )}%`
                  : `${Math.round(
                      ((returnamount - lastmonth) / returnamount) * 100
                    )}%`
                : "0%"}
            </p>
            {/* <button className="w-full h-12 mt-3 bg-slate-900 rounded-md text-white hover:bg-white hover:duration-500 hover:text-black hover:border-2 hover:border-black">
    Know More
  </button> */}
          </div>
        </div>
        <div className="flex gap-4 w-full">
          <div className="w-full">
            <div className="h-96 mt-5 rounded-sm shadow-2xl p-5">
              <div className="flex justify-between items-center">
                <h1 className="text-xl font-extrabold">
                  Conversion of the week
                </h1>
              </div>
              <div className="py-5">
                <Line data={data}></Line>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="h-96 mt-5 rounded-sm shadow-2xl p-5">
              <div className="flex justify-between items-center">
                <h1 className="text-xl font-extrabold">Overdue Students</h1>
              </div>
              <div className="py-5">
                <div className="flex gap-2 justify-between">
                  <div>
                    <h6 className="font-black pb-3">Name</h6>
                    {overduedata.length !== 0
                      ? overduedata.map((data, index) => (
                          <p className="font-normal text-slate-400" key={index}>
                            {data.Student.name}
                          </p>
                        ))
                      : null}
                  </div>
                  <div>
                    <h6 className="font-black pb-3">Credit</h6>
                    {overduedata.length !== 0
                      ? overduedata.map((data, index) => (
                          <p className="font-normal text-slate-400" key={index}>
                            {Math.round(data.amount)}
                          </p>
                        ))
                      : null}
                  </div>
                  <div>
                    <h6 className="font-black pb-3">Due Date</h6>
                    {overduedata.length !== 0
                      ? overduedata.map((data, index) => (
                          <p className="font-normal text-slate-400" key={index}>
                            {moment(data.duedate).format("DD-MM-YYYY")}
                          </p>
                        ))
                      : null}
                  </div>
                  <div>
                    <h6 className="font-black pb-3">Action</h6>
                    {overduedata.length !== 0
                      ? overduedata.map((data, index) => (
                          <p
                            className="font-normal text-slate-400 mt-2"
                            key={index}
                          >
                            <a href={`tel:+91${data.Student.phone}`}>
                              {" "}
                              <FaPhoneAlt />
                            </a>
                          </p>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
