/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import staffProfile from "../Assets/staffProfile.png";
import Group2 from "../Assets/Group 2.png";
import phone from "../Assets/phone.png";
import mail from "../Assets/mail.png";
import user from "../Assets/user.png";
import JoditEditor from "jodit-react";
import { useNavigate, useParams } from "react-router-dom";
import { viewByidStudents } from "../Api/student";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PaymentbyStudent } from "../Api/payment";
import { ProjectbyStudent, createProject } from "../Api/project";
import { viewallstaff } from "../Api/staff";
import moment from "moment/moment";
import { viewallCourses } from "../Api/courses";
import { viewAllBatchByUserId } from "../Api/batch";

const StudentDetails = () => {
  let { id } = useParams();
  const editorRef = useRef(null);
  const navigate = useNavigate();

  const [studentdata, setstudentdata] = useState([]);
  const [paymenthistorydata, setpaymenthistorydata] = useState([]);
  const [projectdata, setprojectdata] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [formdata, setformdata] = useState({
    name: "",
    weblink: "",
    gitlink: "",
    coordinator: "",
    description: "",
  });
  const [allstaffdata, setallstaffdata] = useState([]);
  const [clicked, setclicked] = useState(false);
  const [duration, setduration] = useState(0);
  const [mydata, setmydata] = useState([]);

  useEffect(() => {
    getSingleuser();
  }, [id]);
  const getSingleuser = async () => {
    var studentdata = await viewByidStudents({ id: id });
    setstudentdata(studentdata);
    var paymenthistorydata = await PaymentbyStudent({ id: id });
    setpaymenthistorydata(paymenthistorydata);
    var projectdata = await ProjectbyStudent({ id: id });
    setprojectdata(projectdata);
    var allstaff = await viewallstaff();
    setallstaffdata(allstaff);
    var allcourse = await viewallCourses();
    if (allcourse.length !== 0) {
      var checkdata = await allcourse.filter((data) => {
        return data.title == studentdata[0].course;
      });
      if (checkdata.length !== 0) {
        setduration(checkdata[0].duration);
      }
    }
    var mydata = await viewAllBatchByUserId({ id: id });
    if (mydata.length !== 0) {
      setmydata(mydata);
    }
  };

  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const savebtn = async () => {
    if (formdata.name.length === 0) {
      toast.error("Please Enter Project Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.weblink.length === 0) {
      toast.error("Please Enter Weblink...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.gitlink.length === 0) {
      toast.error("Please Enter Gitlink...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.coordinator.length === 0) {
      toast.error("Please Select Co -ordinator...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setclicked(true);
      formdata["student"] = id;
      var createproject = await createProject(formdata);
      if (createproject.message === "SUCCESS") {
        toast.success("Project Details added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          getSingleuser();
          setcreatestatus(false);
        }, 2000);
        setclicked(false);
      } else {
        setclicked(false);
        toast.success(createproject.message, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  const config = {
    // Toolbar options
    toolbar: true, // Show the toolbar
    toolbarAdaptive: false, // Adaptive toolbar based on window width
    toolbarSticky: true, // Stick the toolbar to the top of the editor
    toolbarButtonSize: "middle", // Size of toolbar buttons: 'small', 'middle', 'big'
    toolbarButtonIcons: true, // Show icons on toolbar buttons
    toolbarButtonText: false, // Show text on toolbar buttons

    // Editor appearance
    theme: "default", // Editor theme: 'default', 'dark'
    language: "en", // Editor language

    // Editor behavior
    spellcheck: true, // Enable spellchecking
    showCharsCounter: true, // Show character counter
    showWordsCounter: true, // Show word counter
    hidePlaceholderOnFocus: false, // Hide the placeholder when the editor gains focus
    showXPathInStatusbar: true, // Show the XPath of the selected element in the status bar

    // Editor content
    allowScript: true, // Allow <script> tags in the editor content
    sanitize: false, // Sanitize the editor content to remove potentially unsafe elements and attributes

    // Plugins and features
    enableDragAndDropFileToEditor: true, // Enable dragging and dropping files into the editor to insert them
    enableResizeEditor: true, // Enable resizing of the editor
    enableSearchReplace: true, // Enable search and replace functionality
    enableCodeMirror: true, // Enable CodeMirror syntax highlighting for HTML source mode
  };
  return (
    <>
      {createstatus === false ? (
        studentdata.length !== 0 ? (
          <div>
            <div className="flex justify-between px-8">
              <h1 className="text-2xl font-black capitalize">
                Students Details about {""}
                <span className="text-3xl font-bold text-blue-600">
                  {studentdata[0].name}
                </span>
              </h1>
              <div className="flex gap-2">
                <button
                  className="bg-blue-500 text-white rounded w-[10rem] font-md py-4"
                  onClick={() => {
                    navigate(`/attendancereport/${id}`);
                  }}
                >
                  View Attendance
                </button>
                <button
                  className="bg-black text-white rounded w-[10rem] font-md py-4"
                  onClick={() => {
                    setcreatestatus(true);
                    setformdata({
                      name: "",
                      weblink: "",
                      gitlink: "",
                      coordinator: "",
                      description: "",
                    });
                  }}
                >
                  Add Project
                </button>
              </div>
            </div>
            <div className="flex justify-between px-10 w3/4 items-center pb-5">
              <div className="w-[30rem] py-3 bg-gray-50 rounded-lg shadow-2xl bg-blend-color shadow-slate-400">
                <div className="flex px-2">
                  <img
                    className="w-20 h-20 object-cover mt-2 rounded"
                    src={
                      studentdata[0].profile === null
                        ? staffProfile
                        : studentdata[0].profile
                    }
                    alt="staffProfile"
                  />
                  <div className="flex flex-col p-3 ">
                    <h1 className="text-2xl font-black">
                      {studentdata[0].name}
                    </h1>
                    <h3>{studentdata[0].course}</h3>
                    <div className="flex gap-2">
                      <h3 className="flex text-medium">
                        <img
                          className="mt-1 h-4 w-5 object-contain"
                          src={user}
                          alt="user"
                        />
                        Referred:
                      </h3>
                      <h3 className="text-bold font-xl">
                        {studentdata[0].manageby}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between px-2 text-bold gap-4 items-center">
                  <div className="">
                    <p className="text-g flex gap-2 items-center">
                      <img
                        className="h-4 w-4 object-contain"
                        src={mail}
                        alt="mail"
                      />
                      {studentdata[0].email}
                    </p>
                  </div>
                  <p className="flex gap-2 items-center">
                    <img
                      className="h-4 w-4 object-contain"
                      src={phone}
                      alt="phone"
                    />
                    {studentdata[0].phone}
                  </p>
                </div>
                <hr className="my-2 border-gray-500" />
                <div className="flex justify-between px-2 text-bold gap-4 items-center">
                  <div>
                    <p>Joined on</p>
                    <p className="text-xl">
                      {moment(studentdata[0].joiningdate).format("DD-MM-YYYY")}
                    </p>
                  </div>{" "}
                  <div>
                    <p>Course Completion</p>
                    <p className="text-xl">
                      {" "}
                      {moment(studentdata[0].joiningdate)
                        .add(duration, "days")
                        .format("DD-MM-YYYY")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col  h-40  w-48 p-3  bg-gray-50 rounded-lg shadow-2xl bg-blend-color shadow-slate-400">
                <h1 className=" text-bold text-xl ">Batch</h1>
                <p className="p-4 text-xl text-green-600 text-center">
                  {mydata.length !== 0 && mydata[0].name}
                </p>
              </div>
              <div className="flex flex-col p-2 h-40 w-48  bg-gray-50 rounded-lg shadow-2xl bg-blend-color shadow-slate-400">
                <h1 className="text-bold text-xl">Projects</h1>
                <p className="text-6xl p-4 underline decoration-solid decoration-3 text-blue-600 text-center">
                  {projectdata.length}
                </p>
              </div>
              <div className="font-bold text-2xl  leading-10  ">
                <h1>Course Fees:</h1>
                <h1>Fees Paid: </h1>
                <h1>Balance:</h1>
              </div>
              <div className="font-bold text-2xl p-4 leading-10">
                <h1>
                  {studentdata[0].fees === null ? 0 : studentdata[0].fees}
                </h1>
                <h1>
                  {studentdata[0].paidfees === null
                    ? 0
                    : studentdata[0].paidfees}
                </h1>
                <h1>
                  {studentdata[0].pendingfees === null
                    ? 0
                    : studentdata[0].pendingfees}
                </h1>
              </div>
            </div>
            {paymenthistorydata.length !== 0 ? (
              <>
                <h1 className="text-2xl font-bold mt-5 ml-10 text-green-600">
                  Payment History
                </h1>
                <hr className="border border-gray-500 m-2 mx-10" />
                <table className="w-11/12 text-center ml-10 mt-5">
                  <tr>
                    <th>S.no</th>
                    <th>Payment Date</th>
                    <th>Paid Amount</th>
                    <th>Pending Amount</th>
                    <th>Mode</th>
                  </tr>

                  {paymenthistorydata.length !== 0
                    ? paymenthistorydata.map((data, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{data.date}</td>
                          <td> ₹ {data.cost} /-</td>
                          <td> ₹ {data.pendingfees} /-</td>
                          <td> {data.mode}</td>
                        </tr>
                      ))
                    : null}
                </table>
              </>
            ) : null}
            {projectdata.length !== 0 ? (
              <>
                <h1 className="text-2xl font-bold mt-5 ml-10 text-green-600">
                  Projects
                </h1>
                <hr className="border border-gray-500 m-2 mx-10" />

                <table className="w-11/12 text-center ml-10 mt-5">
                  <tr>
                    <th>S.no</th>
                    <th>Name</th>
                    <th>Web Link</th>
                    <th>Git Link</th>
                    <th>Co-ordinator</th>
                  </tr>
                  {projectdata.length !== 0
                    ? projectdata.map((data, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{data.name}</td>
                          <td>{data.weblink}</td>
                          <td>{data.gitlink}</td>
                          <td>{data.coordinator}</td>
                        </tr>
                      ))
                    : null}
                </table>
              </>
            ) : null}
          </div>
        ) : null
      ) : (
        <div className="px-14 min-h-screen">
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={() => {
              setcreatestatus(false);
              setformdata({
                name: "",
                weblink: "",
                gitlink: "",
                coordinator: "",
                description: "",
              });
            }}
          >
            <WestIcon />
            <h1 className="text-2xl py-4 font-black">Add New Project</h1>
          </div>
          <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
            <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-5">
              <div>
                <div className="py-3">
                  <h1 className="text-lg">Project Name</h1>
                  <input
                    placeholder="Project Name"
                    type="text"
                    name="name"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    value={formdata.name}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Web Link</h1>
                  <input
                    placeholder="Web Link"
                    type="text"
                    name="weblink"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.weblink}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Git Link</h1>
                  <input
                    placeholder="Git Link"
                    type="text"
                    name="gitlink"
                    className="border-2 p-2 w-full py-2 rounded"
                    onChange={handleChange}
                    defaultValue={formdata.gitlink}
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Description</h1>
                  <JoditEditor
                    ref={editorRef}
                    value={formdata.description}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) =>
                      setformdata((values) => ({
                        ...values,
                        description: newContent,
                      }))
                    }
                    className="subjecttag"
                  />
                </div>
                <div className="py-3">
                  <h1 className="text-lg">Co-ordinator</h1>
                  <select
                    className="border-2 p-2 w-full py-2 rounded"
                    name="coordinator"
                    onChange={handleChange}
                    defaultValue={formdata.coordinator}
                  >
                    <option>Select Co-ordinator</option>
                    {allstaffdata.length !== 0
                      ? allstaffdata.map((data, index) => (
                          <option
                            value={`${data.firstname} ${data.lastname}`}
                            key={index}
                          >
                            {data.firstname} {data.lastname}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
            <div className="py-8">
              {clicked === true ? (
                <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                  Please Wait...
                </button>
              ) : (
                <button
                  className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                  onClick={savebtn}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default StudentDetails;
