import { Attendance } from "../axios";

export const createAttendance = async (data) => {
  var createAttendance = await Attendance.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createAttendance;
};

export const AttendancebyStudent = async (data) => {
  var AttendancebyStudent = await Attendance.post(`/attendancebystudent`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return AttendancebyStudent;
};

export const viewallattendancebyStudent = async (data) => {
  var viewallattendancebyStudent = await Attendance.post(
    `/viewallattendance`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallattendancebyStudent;
};

export const addAttendance = async (data) => {
  var addattendance = await Attendance.post(`/addattendance`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return addattendance;
};

export const TodayAttancedata = async (data) => {
  var TodayAttancedata = await Attendance.get(`/todayAttendance`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return TodayAttancedata;
};
