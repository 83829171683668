import React from "react";
import { Route, Routes } from "react-router-dom";
import StudentsInfo from "../Pages/StudentsInfo";
import CourseDetails from "../Pages/CourseDetails";
import Finance from "../Pages/Finance";
import StaffDetails from "../Pages/StaffDetails";
import StudentDetails from "../Pages/StudentDetails";
import CreateAppointment from "../Pages/CreateAppointment";
import Appointments from "../Pages/Appointments";
import Staffinfo from "../Pages/Staffinfo";
import LeadDatabase from "../Pages/LeadDatabase";
import Expense from "../Components/Accounting/Expense";
import Return from "../Components/Accounting/Return";
import Lesson from "../Components/Course/Lesson";
import Module from "../Components/Course/Module";
import Course from "../Components/Course/Course";
import Editcourse from "../Components/Course/Editcourse";
import Facility from "../Pages/Facility";
import Dashboard from "../Pages/Dashboard";
import PaymentHistory from "../Pages/PaymentHistory";
// import Status from "../Pages/Status";
import Profile from "../Pages/Profile";
import Settings from "../Pages/Settings";
import Mycourse from "../Pages/Mycourse";
import Project from "../Pages/Project";
import Myattendance from "../Pages/Myattendance";
import Attendance from "../Pages/Attendance";
import StudentTicket from "../Pages/StudentTicket";
import Ticket from "../Pages/Ticket";
import CodingPractice from "../Pages/CodingPractice";
import Students from "../Pages/Quiz/Students";
import Studentpage from "../Pages/Quiz/Studentpage";
import Viewquiz from "../Pages/Quiz/StudentPages1";
import CertificateGenerator from "../Pages/CertificateGenerator";
import Entrolldata from "../Pages/Enrolldata";
import Contactuser from "../Pages/Contactuser";
import CoverPicture from "../Pages/CoverPicture";
import Questiondata from "../Pages/Questiondata";
import Coupon from "../Components/Accounting/Coupon";
import Test from "../Pages/Test";
import FailStatus from "../Pages/FailStatus";
import Status from "../Pages/Status";
import LinkCreate from "../Pages/LinkCreate";
import Existing from "../Pages/Existing";
import Refundpolicy from "../Pages/Refundpolicy";
import Termscondition from "../Pages/Termscondition";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import EditModule from "../Components/Course/EditModule";
import EditLessons from "../Components/Course/EditLessons";
import { Hire } from "../Pages/Hire";
import AttendanceReport from "../Pages/AttendanceReport";
import StaffAttendance from "../Pages/StaffAttendance";
import BatchList from "../Components/Batch/BatchList";
import BatchInfo from "../Components/Batch/BatchInfo";

const Index = () => {
  return (
    <Routes>
      <Route path="/students" element={<StudentsInfo />} />
      <Route path="/courses" element={<CourseDetails />} />
      <Route path="/finance" element={<Finance />} />
      <Route path="/staffDetails/:id" element={<StaffDetails />} />
      <Route path="/studentDetails/:id" element={<StudentDetails />} />
      <Route path="/appointments" element={<Appointments />} />
      <Route path="/createappointment" element={<CreateAppointment />} />
      <Route path="/staff" element={<Staffinfo />} />
      <Route path="/leads" element={<LeadDatabase />} />
      <Route path="/expense" element={<Expense />} />
      <Route path="/return" element={<Return />} />
      <Route path="/createlesson" element={<Lesson />} />
      <Route path="/createmodule" element={<Module />} />
      <Route path="/createcourse" element={<Course />} />
      <Route path="/editcourse/:id" element={<Editcourse />} />
      <Route path="/editmodule/:id" element={<EditModule />} />
      <Route path="/editlessons/:id" element={<EditLessons />} />
      <Route path="/facility" element={<Facility />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="/payment" element={<PaymentHistory />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/mycourse" element={<Mycourse />} />
      <Route path="/project" element={<Project />} />
      <Route path="/myattendance" element={<Myattendance />} />
      <Route path="/attendance" element={<Attendance />} />
      <Route path="/success" element={<Status />} />
      <Route path="/failure" element={<FailStatus />} />
      <Route path="/studentticket" element={<StudentTicket />} />
      <Route path="/ticket" element={<Ticket />} />
      <Route path="/codingpractice" element={<CodingPractice />} />
      <Route path="/quiz" element={<Students />} />
      <Route path="/addquiz" element={<Studentpage />} />
      <Route path="/viewquiz" element={<Viewquiz />} />
      <Route path="/certificategenerator" element={<CertificateGenerator />} />
      <Route path="/entrolldata" element={<Entrolldata />} />
      <Route path="/contactuser" element={<Contactuser />} />
      <Route path="/coverpicture" element={<CoverPicture />} />
      <Route path="/questiondata" element={<Questiondata />} />
      <Route path="/coupon" element={<Coupon />} />
      <Route path="/test" element={<Test />} />
      <Route path="/create_by_link" element={<LinkCreate />} />
      <Route path="/add_existing_student" element={<Existing />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<Termscondition />} />
      <Route path="/refundpolicy" element={<Refundpolicy />} />
      <Route path="/hire" element={<Hire />} />
      <Route path="/attendancereport/:id" element={<AttendanceReport />} />
      <Route path="/staffattendance" element={<StaffAttendance />} />
      <Route path="/batchlist" element={<BatchList />} />
      <Route path="/batchinfo/:id" element={<BatchInfo />} />
    </Routes>
  );
};

export default Index;
