import { Staffattendance } from "../axios";

export const createStaffattendance = async (data) => {
  var createStaffattendance = await Staffattendance.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createStaffattendance;
};

export const Attendancebystaff = async (data) => {
  var Attendancebystaff = await Staffattendance.post(`/attendancebystaff`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return Attendancebystaff;
};

export const viewallattendancebystaff = async (data) => {
  var viewallattendancebystaff = await Staffattendance.post(
    `/viewallattendance`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallattendancebystaff;
};

export const addAttendance = async (data) => {
  var addattendance = await Staffattendance.post(`/addattendance`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return addattendance;
};

export const TodayAttancedata = async (data) => {
  var TodayAttancedata = await Staffattendance.get(`/todayAttendance`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return TodayAttancedata;
};
