/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UpdateBatch, ViewByidBatch } from "../../Api/batch";
import { ViewCoursesInfo } from "../../Api/courses";

const Module = () => {
  let { id } = useParams();

  const [batchdata, setbatchdata] = useState([]);
  const [moduledata, setmoduledata] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    GetCourseInfo();
  }, [id]);

  const GetCourseInfo = async () => {
    const batchResponse = await ViewByidBatch({ id: id });
    if (batchResponse.length !== 0) {
      setloading(true);
      const batch = batchResponse[0];
      setbatchdata([batch]);

      const completedModules = batch.completed_module
        ? batch.completed_module.split(",")
        : [];
      if (completedModules.join(",") !== selectedItems.join(",")) {
        setSelectedItems(completedModules);
      }

      const courseResponse = await ViewCoursesInfo({ id: batch.type });
      if (courseResponse.length !== 0) {
        const course = courseResponse[0];
        if (course.modules.length !== 0 && course.modules !== moduledata) {
          setmoduledata(course.modules);
          setloading(false);
        }
      }
    }
  };

  const handleCheckboxChange = async (id) => {
    setSelectedItems((prevSelected) => {
      const updatedItems = prevSelected.includes(String(id))
        ? prevSelected.filter((item) => item !== String(id))
        : [...prevSelected, String(id)];

      const stringdata = updatedItems.join(",");
      const obj = {
        id: batchdata[0].id,
        completed_module: stringdata,
      };

      UpdateBatch(obj).then((updatedata) => {
        if (updatedata === "Updated Successfully") {
          GetCourseInfo();
        }
      });

      return updatedItems;
    });
  };
  return (
    <div>
      <h1 className="text-xl font-bold mt-5 text-green-600">Module List</h1>
      <hr className="border border-gray-500 my-2" />
      <table className="w-full text-center mt-5">
        <thead>
          <tr>
            <th>S.no</th>
            <th>Module Name</th>
            <th>Syllabus</th>
            <th>Select</th>
            <th>Task</th>
          </tr>
        </thead>
        <tbody>
          {loading == false ? (
            moduledata.length !== 0 ? (
              moduledata.map((data, index) => (
                <tr key={data.id}>
                  <td>{index + 1}</td>
                  <td>{data.title}</td>
                  <td>
                    <ul className="list-disc pl-5 text-start">
                      {data.lesson_data.length !== 0
                        ? data.lesson_data.map((item, lessonIndex) => (
                            <li key={lessonIndex}>{item.title}</li>
                          ))
                        : "No lessons available"}
                    </ul>
                  </td>
                  <td>
                    <input
                      className="mr-2"
                      type="checkbox"
                      id={`checkbox-${data.id}`}
                      checked={selectedItems.includes(String(data.id))}
                      onChange={() => handleCheckboxChange(data.id)}
                    />
                  </td>
                  <td>
                    {data.task.length !== 0 ? (
                      <button
                        className="bg-blue-500 text-white p-2 rounded"
                        onClick={() => window.open(data.task, "__blank")}
                      >
                        View
                      </button>
                    ) : (
                      "No task available"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <>
                <td colSpan={7}>No Users Available</td>
              </>
            )
          ) : (
            <>
              <td colSpan={7}>Please Wait...</td>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Module;
